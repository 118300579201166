<template>
    <loading
        v-model:active="mostrarLoader"
        color="#0F80C1"
        loader="dots"
    />

<!--  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />-->
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow" />
   <section class="page__title-area pt-120 pb-90">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="../assets/img/page-title/page-title-shape-6.png" alt="">
      <img class="page-title-shape-7" src="../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="course__wrapper">
               <div class="page__title-content mb-25">
                  <div class="page__title-breadcrumb">
                        <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item">
                              <router-link to="/">Inicio</router-link>
                           </li>
                           <li class="breadcrumb-item">
                             <router-link v-if="course.tipo_registro=='CURSO'" to="/cursos">Cursos</router-link>
                             <router-link v-if="course.tipo_registro=='PROGRAMA'" to="/programas">Programas</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">{{ course.name }}</li>
                        </ol>
                        </nav>
                  </div>
                  <span v-if="course.tipo_registro=='CURSO'" class="page__title-pre blue">Curso</span>
                  <span v-else-if="course.tipo_registro=='PROGRAMA'" class="page__title-pre green">Programa</span>
                  <h5 class="page__title-3">{{ course.name }}</h5>
               </div>
               <div class="course__meta-2 d-sm-flex mb-30">
                  <div class="course__teacher-3 d-flex align-items-center mr-70 mb-30" v-for="teacher in course.teachers" :key="teacher.token">
                      <div class="course__teacher-thumb-3 mr-15">
                        <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank">
                          <img :src="teacher.avatar_image_url" alt="">
                        </a>

                        <img :src="teacher.avatar_image_url" alt="" v-else>
                     </div>
                     <div class="course__teacher-info-3">
                        <h5>Docente:</h5>
                        <p>
                          <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank"> {{ teacher.display_name }}</a>
                          <a v-else href="#"> {{ teacher.display_name }}</a>
                        </p>
                     </div>
                  </div>
<!--                  <div class="course__update mr-80 mb-30">-->
<!--                     <h5>Publicación:</h5>-->
<!--                     <p>{{ course.fecha_creacion }}</p>-->
<!--                  </div>-->
                  <div class="course__update mr-80 mb-30" v-if="course.start_at">
                     <h5>Fecha inicio:</h5>
                     <p>{{ course.start_at }}</p>
                  </div>
                  <div class="course__update mr-80 mb-30" v-if="course.end_at">
                     <h5>Fecha finalización:</h5>
                     <p>{{ course.end_at }}</p>
                  </div>
                  <div class="course__update mr-80 mb-30" v-if="course.fechas_clases_vivo && course.fechas_clases_vivo.length > 0">
                     <h5>Fechas clases presenciales:</h5>
                     <p v-for="fecha in course.fechas_clases_vivo" :key="fecha.fecha_clase_vivo">{{ fecha.fecha_clase_vivo }}</p>
                  </div>
<!--                  <div class="course__rating-2 mb-30">-->
<!--                     <h5>Review:</h5>-->
<!--                     <div class="course__rating-inner d-flex align-items-center">-->
<!--                        <ul>-->
<!--                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>-->
<!--                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>-->
<!--                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>-->
<!--                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>-->
<!--                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>-->
<!--                        </ul>-->
<!--                        <p>5.0</p>-->
<!--                     </div>-->
<!--                  </div>-->
               </div>
               <div class="course__img w-img mb-30">
                  <img v-if="course.course_image" :src="course.course_image" alt="course-image">
                  <img v-else src="@/assets/img/pages/not-available.png" alt="course-image" style="max-height: 460px">
               </div>
               <div class="course__tab-2 mb-45">
                  <ul class="nav nav-tabs" id="courseTab" role="tablist" style="flex-wrap:nowrap">
                     <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true"> <i class="fal fa-edit"></i> <span>Descripción</span> </button>
                     </li>
                     <li class="nav-item" role="presentation" id="curriculum-tab-list" v-if="course.mostrar_brochure_contenido">
                        <button class="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false" @click="cargarCurriculumTab"> <i class="fal fa-book-alt"></i> <span>Contenido</span> </button>
                     </li>
<!--                     <li class="nav-item" role="presentation">-->
<!--                        <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false"> <i class="icon_star_alt"></i> <span>Reviews</span> </button>-->
<!--                     </li>-->
                     <li class="nav-item" role="presentation" id="formacion-tab-list" v-if="course.mostrar_formacion">
                        <button class="nav-link" id="formacion-tab" data-bs-toggle="tab" data-bs-target="#formacion" type="button" role="tab" aria-controls="formacion" aria-selected="false" @click="cargarFormacionTab"> <i class="fal fa-graduation-cap"></i> <span>Meta</span> </button>
                     </li>
                     <li class="nav-item" role="presentation" v-if="course.mostrar_docentes">
                        <button class="nav-link" id="member-tab" data-bs-toggle="tab" data-bs-target="#member" type="button" role="tab" aria-controls="member" aria-selected="false"> <i class="fal fa-user"></i> <span>Docentes</span> </button>
                     </li>
                     <li class="nav-item" role="presentation" id="faq-tab-list" v-if="course.mostrar_faq">
                        <button class="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false" @click="cargarFaqTab"> <i class="fal fa-question"></i> <span>FAQ</span> </button>
                     </li>
                  </ul>
               </div>
               <div class="course__tab-content mb-50">
                  <div class="tab-content" id="courseTabContent">
                     <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                        <div class="course__description">
<!--                           <h3>Acerca del curso</h3>-->
                            <img v-if="course.banner_descripcion_2" :src="course.banner_descripcion_2" alt="banner_descripcion_2" style="max-height: 230px; max-width: 100%;">
                            <br v-if="course.banner_descripcion_2">
                            <br v-if="course.banner_descripcion_2">

                            <h4>Objetivos</h4>
                            <p style="text-align: justify; white-space: pre-line" class="ml-20" v-if="course.objetivo" v-html="course.objetivo"></p>
                            <p v-else class="">No existe información para mostrar</p>
                            <br>

                            <h4>A quién va dirigido</h4>
                            <p style="text-align: justify; white-space: pre-wrap" class="ml-20" v-if="course.dirigido_a" v-html="course.dirigido_a"></p>
                            <p v-else>No existe información para mostrar</p>
                            <br>

                            <img v-if="course.banner_descripcion" :src="course.banner_descripcion" alt="banner_descripcion" style="max-height: 230px; max-width: 100%;">
                            <br v-if="course.banner_descripcion">
                            <br v-if="course.banner_descripcion">

<!--                           <div class="course__tag-2 mb-35 mt-35">-->
<!--                              <i class="fal fa-tag"></i>-->
<!--                              <a href="#">Big data,</a>-->
<!--                              <a href="#">Data analysis,</a>-->
<!--                              <a href="#">Data modeling</a>-->
<!--                           </div>-->
<!--                           <div class="course__description-list mb-45">-->
<!--                              <h4>What is the Target Audience?</h4>-->
<!--                              <ul>-->
<!--                                 <li> <i class="icon_check"></i> Business's managers, leaders</li>-->
<!--                                 <li> <i class="icon_check"></i> Downloadable lectures, code and design assets for all projects</li>-->
<!--                                 <li> <i class="icon_check"></i> Anyone who is finding a chance to get the promotion</li>-->
<!--                              </ul>-->
<!--                           </div>-->
<!--                           <div class="course__instructor mb-45">-->
<!--                              <h3>Other Instructors</h3>-->
<!--                              <div class="course__instructor-wrapper d-md-flex align-items-center">-->
<!--                                 <div class="course__instructor-item d-flex align-items-center mr-70">-->
<!--                                    <div class="course__instructor-thumb mr-20">-->
<!--                                       <img src="../assets/img/course/teacher/teacher-3.jpg" alt="">-->
<!--                                    </div>-->
<!--                                    <div class="course__instructor-content">-->
<!--                                       <h3>Eleanor Fant</h3>-->
<!--                                       <p>Instructor</p>-->
<!--                                    </div>-->
<!--                                 </div>-->
<!--                                 <div class="course__instructor-item d-flex align-items-center mr-70">-->
<!--                                    <div class="course__instructor-thumb mr-20">-->
<!--                                       <img src="../assets/img/course/teacher/teacher-2.jpg" alt="">-->
<!--                                    </div>-->
<!--                                    <div class="course__instructor-content">-->
<!--                                       <h3>Lauren Stamps</h3>-->
<!--                                       <p>Teacher</p>-->
<!--                                    </div>-->
<!--                                 </div>-->
<!--                                 <div class="course__instructor-item d-flex align-items-center mr-70">-->
<!--                                    <div class="course__instructor-thumb mr-20">-->
<!--                                       <img src="../assets/img/course/teacher/teacher-1.jpg" alt="">-->
<!--                                    </div>-->
<!--                                    <div class="course__instructor-content">-->
<!--                                       <h3>Jonquil Von</h3>-->
<!--                                       <p>Associate</p>-->
<!--                                    </div>-->
<!--                                 </div>-->
<!--                              </div>-->
<!--                           </div>-->
                        </div>
                     </div>

                     <!-- CurriculumTab start -->
                     <CurriculumTab v-if="course.mostrar_brochure_contenido" :modulos="course.modulos" :brochure="course.brochure" :planificacion="course.planificacion" :downloadPdfPlanificacion="downloadPdfPlanificacion" :curriculumKey="curriculumKey" :mostrarBrochure="mostrarBrochure"/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->
<!--                     <ReviewTab/>-->
                     <!-- CurriculumTab end -->

                     <!-- MemberTab start -->
                     <MemberTab v-if="course.mostrar_docentes" :teachers="course.teachers"/>
                     <!-- MemberTab end -->

                     <!-- FaqTab start -->
                      <FormacionTab v-if="course.mostrar_formacion" :programaFormacion="course.programa_formacion" :formacionKey="formacionKey" :mostrarFormacion="mostrarFormacion"/>
                     <!-- FaqTab end -->

                     <!-- FaqTab start -->
                      <FaqTab v-if="course.mostrar_faq" :faq="course.faq" :faqKey="faqKey" :mostrarFaq="mostrarFaq" :faqLista="course.faq_renderizado"/>
                     <!-- FaqTab end -->

<!--                     <div class="course__share">-->
<!--                        <h3>Compartir :</h3>-->
<!--                        <ul>-->
<!--                           <li>-->
<!--                              <a href="#" class="fb" ><i class="fab fa-facebook-f"></i>-->
<!--                           </a></li>-->
<!--                           <li>-->
<!--                              <a href="#" class="tw" ><i class="fab fa-twitter"></i>-->
<!--                           </a></li>-->
<!--                           <li>-->
<!--                              <a href="#" class="pin" ><i class="fab fa-pinterest-p"></i>-->
<!--                           </a></li>-->
<!--                        </ul>-->
<!--                     </div>-->
                  </div>
               </div>
            </div>
         </div>

         <!-- course side bar start -->
         <CourseSidebar :course="course"/>
         <!-- course side bar end -->
      </div>

       <div class="row" v-if="course.tipo_registro=='PROGRAMA'">
           <!--          lista de cursos start-->
           <div class="col-xxl-12">
              <div class="section__title-wrapper mb-40">
                 <h2 class="section__title">Contenido del <span class="yellow-bg yellow-bg-big">Programa<img src="../assets/img/shape/yellow-bg.png" alt=""></span></h2>
              </div>
           </div>
            <div v-for="detalle in course.detalles" :key="detalle.token" class="col-xxl-12">
               <div class="course__item white-bg mb-30 fix">
                  <div class="row gx-0">
                     <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="course__thumb course__thumb-list w-img p-relative fix">
                            <a :href="detalle.course.proximamente ? 'javascript:void(0);' : `${rutaCompleta}curso/${detalle.course.codigo}`">
                               <img v-if="detalle.course.course_image" :src="detalle.course.course_image" alt="course-image">
                                <img v-else src="@/assets/img/pages/not-available.png" alt="course-image">
                            </a>
                           <div class="course__tag" v-if="detalle.course.tipo_registro=='CURSO'">
<!--                               <a v-if="detalle.course.proximamente" href="javascript:void(0);" class="orange">Próximamente</a>-->
                              <a href="javascript:void(0);" class="green">Curso</a>
                           </div>
                        </div>
                     </div>
                     <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="course__right">
                           <div class="course__content course__content-3">
                              <div class="course__meta d-flex align-items-center">
                                 <div class="course__lesson mr-20" v-if="detalle.course.modulos && detalle.course.modulos.length>0">
                                    <span><i class="far fa-book-alt"></i> {{ detalle.course.modulos.length }} Secciones</span>
                                 </div>
<!--                                 <div class="course__rating">-->
<!--                                    <span><i class="icon_star"></i>{{course.rating}} (44)</span>-->
<!--                                 </div>-->
                              </div>
                              <h4 class="course__title course__title-4">
                                 <a v-if="!detalle.course.proximamente" :href="detalle.course.proximamente ? 'javascript:void(0);' : `${rutaCompleta}curso/${detalle.course.codigo}`">
                                    {{ detalle.course.name }}
                                 </a>
                                  <span v-else>{{ detalle.course.name }}</span>
                              </h4>
                              <div class="course__summary" v-if="detalle.course.start_at">
                                 <p><b>Fecha inicio: </b>{{ detalle.course.start_at }}</p>
                              </div>
                              <div class="course__teacher d-flex align-items-center" v-for="teacher in detalle.course.teachers" :key="teacher.token">
                                 <div class="course__teacher-thumb mr-15">
                                    <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank">
                                      <img :src="teacher.avatar_image_url" alt="">
                                    </a>

                                    <img :src="teacher.avatar_image_url" alt="" v-else>
                                 </div>
                                 <h6>
                                    <b>Docente: </b>
                                    <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank"> {{ teacher.display_name }}</a>
                                    <a v-else href="#"> {{ teacher.display_name }}</a>
                                 </h6>
                              </div>
                           </div>
                           <div class="course__more course__more-2 d-flex justify-content-between align-items-center">
                               <div class="row">
                                   <div v-if="detalle.course.tiene_modalidad_hibrida" class="course__status d-flex flex-column align-items-center col-5">
                                       <span v-if="detalle.course.info_descuento_promocion && detalle.course.info_descuento_promocion.aplica_descuento" class="green">$ {{ detalle.course.info_descuento_promocion.precio_postdescuento }}</span>
                                       <span v-else-if="detalle.course.info_descuento_promocion && !detalle.course.info_descuento_promocion.aplica_descuento" class="green">$ {{ detalle.course.precio_modalidad_hibrida }}</span>
                                       <div class="course__tag" style="position: static;">
                                           <a href="#" class="orange">SEMIPRESENCIAL</a>
                                       </div>
                                   </div>

                                   <div class="col-2">

                                   </div>

                                    <div v-if="detalle.course.tiene_modalidad_virtual" class="course__status d-flex flex-column col-5">
                                       <span v-if="detalle.course.info_descuento_promocion && detalle.course.info_descuento_promocion.aplica_descuento" class="green">$ {{ detalle.course.info_descuento_promocion.precio_postdescuento }}</span>
                                       <span v-else-if="detalle.course.info_descuento_promocion && !detalle.course.info_descuento_promocion.aplica_descuento" class="green">$ {{ detalle.course.precio_modalidad_virtual }}</span>
                                       <div class="course__tag" style="position: static;">
                                           <a href="#" class="orange">ONLINE</a>
                                       </div>
                                    </div>
                               </div>
<!--                              <div class="course__status d-flex align-items-center" v-if="detalle.course.info_descuento_promocion && detalle.course.info_descuento_promocion.aplica_descuento">-->
<!--                                 <span class="green">$ {{ detalle.course.info_descuento_promocion.precio_postdescuento }}</span>-->
<!--                                 <span class="old-price">$ {{ detalle.course.precio}}</span>-->
<!--                              </div>-->
<!--                              <div class="course__status d-flex align-items-center" v-else-if="detalle.course.info_descuento_promocion && !detalle.course.info_descuento_promocion.aplica_descuento">-->
<!--                                 <span class="green">$ {{ detalle.course.precio }}</span>-->
<!--                              </div>-->
                              <div class="course__btn">
                                  <a :href="detalle.course.proximamente ? 'javascript:void(0);' : `${rutaCompleta}curso/${detalle.course.codigo}`" class="link-btn">
      <!--                          <router-link :to="`/course-details/${course.token}`" class="link-btn">-->
                                    Ver detalles
                                    <i class="far fa-arrow-right"></i>
                                    <i class="far fa-arrow-right"></i>
      <!--                         </router-link>-->
                                </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
              </div>
            <!--   lista de cursos end        -->
       </div>

       <div class="row">
           <div class="col-12">
               <div class="course__related">
                  <div class="row">
                     <div class="col-xxl-12">
                        <div class="section__title-wrapper mb-40">
                           <h2 class="section__title">Cursos <span class="yellow-bg yellow-bg-big">Relacionados<img src="../assets/img/shape/yellow-bg.png" alt=""></span></h2>
<!--                           <p>You don't have to struggle alone, you've got our assistance and help.</p>-->
                        </div>
                     </div>
                  </div>
                  <!-- slider courses start-->
                  <SliderCourses/>
                  <!-- slider courses end-->
               </div>
           </div>
       </div>
   </div>
</section>

<!-- Footer -->
<Footer footerPadding="true"/>

</template>

<script>
// import Header from '../components/HomeTwo/Header.vue';
import Header from '../components/Home/Header.vue';
import Footer from '../components/Home/Footer.vue';
import CourseItemMixin from '../mixins/courseItemsMixin';
import SliderCourses from '../components/CourseDetails/SliderCourses.vue';
import CourseSidebar from '../components/CourseDetails/CourseSidebar.vue';
import CurriculumTab from '../components/CourseDetails/CurriculumTab.vue';
// import ReviewTab from '../components/CourseDetails/ReviewTab.vue';
import MemberTab from '../components/CourseDetails/MemberTab.vue';

import axios from '@/axios';

import FaqTab from "@/components/CourseDetails/FaqTab.vue";
import FormacionTab from "@/components/CourseDetails/FormacionTab.vue";
import rutasjson from "@/assets/utils/rutas.json";

export default {
  name: "CourseDetailsPage",
  components:{
      FormacionTab,
      FaqTab,
      Header,
      SliderCourses,
      CourseSidebar,
      CurriculumTab,
      // ReviewTab,
      MemberTab,
      Footer,
	},
    mixins: [CourseItemMixin],
    data() {
        return {
            // id: this.$route.params.id,
            codigo: this.$route.params.codigo,
            course: {},
            mostrarLoader: false,
            curriculumKey: 2000,
            faqKey: 1000,
            formacionKey: 0,
            mostrarBrochure: true,
            mostrarFormacion: false,
            mostrarFaq: false,
            rutaCompleta: rutasjson.rutaCompleta
        }
    },
    methods: {
        // getCourse(courseId) {
        //     this.course = this.courseItems.find(item => item.id == courseId);
        // }
        getCourse(courseCodigo) {
            this.openLoadingInDiv()
            if(this.$route.name=='detalle-curso'){
               //  console.log("es curso------------------------")
                axios.get(`api/curso/${courseCodigo}/`).then(response => {
                    this.course = response.data;
                    this.closeLoadingInDiv()
                });
            }
            else if(this.$route.name=='detalle-programa'){
               //  console.log("es programa------------------------")
                axios.get(`api/programa/${courseCodigo}/`).then(response => {
                    this.course = response.data;
                    this.closeLoadingInDiv()
                });
            }
        },
        openLoadingInDiv(){
            this.mostrarLoader = true
        },
        closeLoadingInDiv(){
            this.mostrarLoader = false
        },

        downloadPdfPlanificacion(){
            axios.post('api/curso/descargar_formato_planificacion/', {
                curso: this.course.token,
                tipo_registro: this.course.tipo_registro
            },{
                responseType: 'blob'
            }).then(response => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download',  this.course.name + ' - Formato de planificación' + '.pdf');
                  document.body.appendChild(link);
                  link.click()
            });
        },

        cargarCurriculumTab(){
            this.curriculumKey += 1
            this.mostrarFaq = false
            this.mostrarFormacion = false
            this.mostrarBrochure = true
        },
        cargarFaqTab(){
            this.faqKey += 1
            this.mostrarFaq = true
            this.mostrarFormacion = false
            this.mostrarBrochure = false
        },
        cargarFormacionTab(){
            this.formacionKey += 1
            this.mostrarFaq = false
            this.mostrarFormacion = true
            this.mostrarBrochure = false
        }
    },
    created() {
        this.getCourse(this.codigo);
    }
};
</script>