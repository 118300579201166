/* eslint-disable */

import rutasjson from "@/assets/utils/rutas.json"

export function verificarCedula(cedula) {
    //console.log("verificar cedula")
    if (typeof(cedula) === 'string' && cedula.length === 10 && /^\d+$/.test(cedula)) {
        var digitos = cedula.split('').map(Number);
        var codigo_provincia = digitos[0] * 10 + digitos[1];

        //if (codigo_provincia >= 1 && (codigo_provincia <= 24 || codigo_provincia == 30) && digitos[2] < 6) {

        if (codigo_provincia >= 1 && (codigo_provincia <= 24 || codigo_provincia == 30)) {
            var digito_verificador = digitos.pop();

            var digito_calculado = digitos.reduce(
            function (valorPrevio, valorActual, indice) {
                return valorPrevio - (valorActual * (2 - indice % 2)) % 9 - (valorActual == 9) * 9;
            }, 1000) % 10;
            return digito_calculado === digito_verificador;
        }
        return false
    }
    else if(cedula.length === 13 && /^\d+$/.test(cedula)){
        return true
    }
    else{
        return false
    }
}

export function verificarRuc(ruc, mostrarTipo = false){
    // var ruc = p_('ruc').value;
    // var ruc_resultado_validacion = p_('ruc_resultado_validacion');
    // var ruc_explicacion_validacion = p_('ruc_explicacion_validacion');
    //
    // ruc_explicacion_validacion.style.display = 'none';

    if (typeof(ruc) === 'string' && ruc.length === 13 && /^\d+$/.test(ruc)) {
        var digitos = ruc.split('').map(Number);
        var codigo_provincia = digitos[0] * 10 + digitos[1];
        var display_noveno;
        var tipo_modulo;
        var coeficientes;
        let tipoPersona = ''

        if ((codigo_provincia >= 1 && codigo_provincia <= 24) || codigo_provincia == 30) {

        	if (digitos[2] != 7 || digitos[2] != 8) {

                  tipo_modulo = (digitos[2] > 5) ? 11 : 10;

                  if (digitos[2] == 6) {
                      //institución pública
                      digitos = digitos.slice(0, 9);
                      // p_('tipo_contribuyente').innerHTML = 'Institución Pública';
                      // p_('posicion_digito_verificador').innerHTML = 'noveno';
                      // p_('numero_digitos_para_calculo').innerHTML = 'ocho';
                      // display_noveno = false;
                      coeficientes = [3, 2, 7, 6, 5, 4, 3, 2];
                      tipoPersona = rutasjson.tipo_persona_juridica
                   } else {
                      //caso normal
                      digitos = digitos.slice(0, 10);
                      // p_('tipo_contribuyente').innerHTML = (digitos[2] == 9) ? 'Persona jurídica y extranjeros sin cédula' : 'Persona natural';
                      tipoPersona = (digitos[2] == 9) ? rutasjson.tipo_persona_juridica : rutasjson.tipo_persona_natural
                      // p_('posicion_digito_verificador').innerHTML = 'décimo';
                      // p_('numero_digitos_para_calculo').innerHTML = 'nueve';
                      // display_noveno = true;
                      coeficientes = (digitos[2] == 9) ? [4, 3, 2, 7, 6, 5, 4, 3, 2] : [2, 1, 2, 1, 2, 1, 2, 1, 2];

                   }
                   // p_display(['x9', 'c9', 'r9', 'ruc_ttt9', 'ruc_ththth9'], display_noveno);

            var digito_verificador = digitos.pop();

            var digito_calculado = tipo_modulo - digitos.reduce(function (valorPrevio, valorActual, indice) {
                // console.log(valorActual, coeficientes[indice], valorActual * coeficientes[indice]);
                var resultado = valorActual * coeficientes[indice];

                if (digitos[2] < 6) { // si es persona natural:
                    resultado = (resultado > 9) ? resultado - 9 : resultado;
                }
                return valorPrevio + resultado;
            }, 0) % tipo_modulo;
            digito_calculado = (digito_calculado === 11) ? 0 : digito_calculado;

            // console.log('digito_calculado:', digito_calculado, tipo_modulo);

            if (digito_calculado === digito_verificador){
              // ruc_resultado_validacion.innerHTML = '<strong>El RUC es válido</strong>';
              var sum= 0;
              digitos.map(function(digito, indice){
                  var posicion = indice + 1;
                  var buff = 0;
                  // p_('x'+posicion).innerHTML = digito;
                  // p_('c'+posicion).innerHTML = coeficientes[indice];
                  buff = digito * coeficientes[indice];
                  // p_('r'+posicion).innerHTML = buff;

                  if (digitos[2] < 6 && buff > 9) {
                      buff -= 9;
                      // p_('r'+posicion).innerHTML += ' - 9 = ' + buff;
                  }
                  sum += buff;
              });

              // p_('ruc_ttt10').innerHTML = '<strong>'+ (sum) + '</strong>';
              //
              // p_('ruc_tttt1').innerHTML = (sum);
              // p_('ruc_tttt2').innerHTML = (sum) % tipo_modulo;
              // p_('ruc_tipo_modulo1').innerHTML = tipo_modulo;
              // p_('ruc_tipo_modulo2').innerHTML = tipo_modulo;
              //
              //
              // p_('ruc_ttttt1').innerHTML = (sum) % tipo_modulo;
              // p_('ruc_ttttt2').innerHTML = tipo_modulo - (sum) % tipo_modulo;
              // p_('ruc_tipo_modulo3').innerHTML = tipo_modulo;
              // p_('ruc_tipo_modulo4').innerHTML = tipo_modulo;

              // if (sum % tipo_modulo === 0) { //caso especial
              //     p_display('ruc_paso5especial', true);
              //     p_display('ruc_paso5normal', false);
              // } else { //caso normal
              //     p_display('ruc_paso5especial', false);
              //     p_display('ruc_paso5normal', true);
              // }
              //
              // p_('ruc_tttttt1').innerHTML = digito_calculado;
              // p_('ruc_tttttt2').innerHTML = ruc;
              //
              // p_('ruc_codigo_provincia').innerHTML = digitos[0] + '' + digitos[1];
              // p_('ruc_nombre_provincia').innerHTML = provincias[digitos[0] * 10 + digitos[1]];
              //
              // p_('ruc_tercer_digito').innerHTML = digitos[2];
              //
              //
              // ruc_explicacion_validacion.style.display = '';

                if(mostrarTipo){
                    return {'validacion': true, 'tipo': tipoPersona}
                }
                else{
                    return true
                }

            } else {
              // ruc_resultado_validacion.innerHTML = 'No coincide el dígito verificador';
            }
          } else {
            // ruc_resultado_validacion.innerHTML = 'El tercer dígito no debe ser 7 o 8';
          }
        } else {
          // ruc_resultado_validacion.innerHTML = 'Los dos primeros dígitos deben estar entre 01 y 24, o 30 para ecuatorianos registrados en el exterior';
        }

        return false
    }
    else if(ruc.length === 10 && /^\d+$/.test(ruc)){
        return true
    }

    else {
        // ruc_resultado_validacion.innerHTML = 'No son 13 dígitos';
        return false
    }
}

export function obtenerTipoPersonaFacturacion(cedulaRuc){
    let tipoPersona = ''
    if (cedulaRuc.length === 10){
        if(verificarCedula(cedulaRuc)){
            return rutasjson.tipo_persona_natural
        }
    }
    else if (cedulaRuc.length === 13){
        const verificarRucResult = verificarRuc(cedulaRuc, true)
        if(verificarRucResult !== false){
            return verificarRucResult['tipo']
        }
    }
    return tipoPersona
}
