<template>
<div class="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
   <div v-if="modulos && modulos.length>0" class="course__curriculum">
      <AccordionList>
          <AccordionItem v-for="modulo in modulos" :key="modulo.token" :id="modulo.token" class="mb-50">
              <template #summary>{{ modulo.name }}</template>
               <div class="course__curriculum-content d-sm-flex justify-content-between align-items-center" v-for="item in modulo.items" :key="item.token">
                  <div class="course__curriculum-info">
                      <svg viewBox="0 0 24 24" v-if="item.type=='ExternalUrl' || item.type=='ExternalTool' || item.type=='Page'">
                          <polygon class="st0" points="23,7 16,12 23,17 "/>
                          <path class="st0" d="M3,5h11c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V7C1,5.9,1.9,5,3,5z"/>
                      </svg>
                      <svg viewBox="0 0 16 16" v-else-if="item.type=='Discussion'">
                          <path class="st0" d="M2,12V8c0-3.3,2.9-6,6.4-6s6.4,2.7,6.4,6v4"/>
                          <path class="st0" d="M14.8,12.7c0,0.7-0.6,1.3-1.4,1.3h-0.7c-0.8,0-1.4-0.6-1.4-1.3v-2c0-0.7,0.6-1.3,1.4-1.3h2.1V12.7z M2,12.7  C2,13.4,2.6,14,3.3,14H4c0.7,0,1.3-0.6,1.3-1.3v-2c0-0.7-0.6-1.3-1.3-1.3H2V12.7z"/>
                      </svg>
                     <svg class="document" viewBox="0 0 24 24" v-else>
                        <path class="st0" d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z"/>
                        <polyline class="st0" points="14,2 14,8 20,8 "/>
                        <line class="st0" x1="16" y1="13" x2="8" y2="13"/>
                        <line class="st0" x1="16" y1="17" x2="8" y2="17"/>
                        <polyline class="st0" points="10,9 9,9 8,9 "/>
                     </svg>

                     <h3> <span>{{ item.position }}: </span> {{ item.title }}</h3>
                  </div>
               </div>
          </AccordionItem>
      </AccordionList>

<!--      <div class="accordion" id="course__accordion">-->
<!--         <div class="accordion-item mb-50" v-for="modulo in modulos" :key="modulo.token">-->
<!--            <h2 class="accordion-header" :id="'modulo-'+modulo.token">-->
<!--               <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#modulo-'+modulo.token+'-content'" aria-expanded="false" :aria-controls="'modulo-'+modulo.token+'-content'">-->
<!--               {{ modulo.name }}-->
<!--               </button>-->
<!--            </h2>-->
<!--            <div :id="'modulo-'+modulo.token+'-content'" class="accordion-collapse collapse" :aria-labelledby="'modulo-'+modulo.token" data-bs-parent="#course__accordion" >-->
<!--               <div class="accordion-body">-->
<!--                   <div class="course__curriculum-content d-sm-flex justify-content-between align-items-center" v-for="item in modulo.items" :key="item.token">-->
<!--                      <div class="course__curriculum-info">-->
<!--                          <svg viewBox="0 0 24 24" v-if="item.type=='ExternalUrl' || item.type=='ExternalTool'">-->
<!--                              <polygon class="st0" points="23,7 16,12 23,17 "/>-->
<!--                              <path class="st0" d="M3,5h11c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V7C1,5.9,1.9,5,3,5z"/>-->
<!--                          </svg>-->
<!--                          <svg viewBox="0 0 16 16" v-else-if="item.type=='Discussion'">-->
<!--                              <path class="st0" d="M2,12V8c0-3.3,2.9-6,6.4-6s6.4,2.7,6.4,6v4"/>-->
<!--                              <path class="st0" d="M14.8,12.7c0,0.7-0.6,1.3-1.4,1.3h-0.7c-0.8,0-1.4-0.6-1.4-1.3v-2c0-0.7,0.6-1.3,1.4-1.3h2.1V12.7z M2,12.7  C2,13.4,2.6,14,3.3,14H4c0.7,0,1.3-0.6,1.3-1.3v-2c0-0.7-0.6-1.3-1.3-1.3H2V12.7z"/>-->
<!--                          </svg>-->
<!--                         <svg class="document" viewBox="0 0 24 24" v-else>-->
<!--                            <path class="st0" d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z"/>-->
<!--                            <polyline class="st0" points="14,2 14,8 20,8 "/>-->
<!--                            <line class="st0" x1="16" y1="13" x2="8" y2="13"/>-->
<!--                            <line class="st0" x1="16" y1="17" x2="8" y2="17"/>-->
<!--                            <polyline class="st0" points="10,9 9,9 8,9 "/>-->
<!--                         </svg>-->

<!--                         <h3> <span>{{ item.position }}: </span> {{ item.title }}</h3>-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <div class="course__curriculum-meta">&ndash;&gt;-->
<!--&lt;!&ndash;                         <span class="time"> <i class="icon_clock_alt"></i> 14 minutes</span>&ndash;&gt;-->
<!--&lt;!&ndash;                         <span class="question">2 questions</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                   </div>-->
<!--               </div>-->
<!--            </div>-->
<!--         </div>-->
<!--      </div>-->

<!--      <div class="accordion" id="course__accordion">-->
<!--         <div class="accordion-item mb-50" v-for="modulo in modulos" :key="modulo.token">-->
<!--            <h2 class="accordion-header" :id="'modulo-'+modulo.token">-->
<!--               <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#modulo-'+modulo.token+'-content'" aria-expanded="false" :aria-controls="'modulo-'+modulo.token+'-content'">-->
<!--               {{ modulo.name }}-->
<!--               </button>-->
<!--            </h2>-->
<!--            <div :id="'modulo-'+modulo.token+'-content'" class="accordion-collapse collapse" :aria-labelledby="'modulo-'+modulo.token" data-bs-parent="#course__accordion" >-->
<!--               <div class="accordion-body">-->
<!--                   <div class="course__curriculum-content d-sm-flex justify-content-between align-items-center" v-for="item in modulo.items" :key="item.token">-->
<!--                      <div class="course__curriculum-info">-->
<!--                          <svg viewBox="0 0 24 24" v-if="item.type=='ExternalUrl' || item.type=='ExternalTool'">-->
<!--                              <polygon class="st0" points="23,7 16,12 23,17 "/>-->
<!--                              <path class="st0" d="M3,5h11c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V7C1,5.9,1.9,5,3,5z"/>-->
<!--                          </svg>-->
<!--                          <svg viewBox="0 0 16 16" v-else-if="item.type=='Discussion'">-->
<!--                              <path class="st0" d="M2,12V8c0-3.3,2.9-6,6.4-6s6.4,2.7,6.4,6v4"/>-->
<!--                              <path class="st0" d="M14.8,12.7c0,0.7-0.6,1.3-1.4,1.3h-0.7c-0.8,0-1.4-0.6-1.4-1.3v-2c0-0.7,0.6-1.3,1.4-1.3h2.1V12.7z M2,12.7  C2,13.4,2.6,14,3.3,14H4c0.7,0,1.3-0.6,1.3-1.3v-2c0-0.7-0.6-1.3-1.3-1.3H2V12.7z"/>-->
<!--                          </svg>-->
<!--                         <svg class="document" viewBox="0 0 24 24" v-else>-->
<!--                            <path class="st0" d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z"/>-->
<!--                            <polyline class="st0" points="14,2 14,8 20,8 "/>-->
<!--                            <line class="st0" x1="16" y1="13" x2="8" y2="13"/>-->
<!--                            <line class="st0" x1="16" y1="17" x2="8" y2="17"/>-->
<!--                            <polyline class="st0" points="10,9 9,9 8,9 "/>-->
<!--                         </svg>-->

<!--                         <h3> <span>{{ item.position }}: </span> {{ item.title }}</h3>-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <div class="course__curriculum-meta">&ndash;&gt;-->
<!--&lt;!&ndash;                         <span class="time"> <i class="icon_clock_alt"></i> 14 minutes</span>&ndash;&gt;-->
<!--&lt;!&ndash;                         <span class="question">2 questions</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                   </div>-->
<!--               </div>-->
<!--            </div>-->
<!--         </div>-->
<!--      </div>-->
   </div>

<!--    <div v-else class="course__description">-->
<!--        <p>No existe información para mostrar</p>-->
<!--    </div>-->
    <br v-if="planificacion">

    <h4 v-if="planificacion">Recursos</h4>
    <div class="course__curriculum-content d-sm-flex justify-content-between align-items-center" v-if="planificacion">
        <div class="course__curriculum-info">
             <svg class="document" viewBox="0 0 24 24">
                <path class="st0" d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z"/>
                <polyline class="st0" points="14,2 14,8 20,8 "/>
                <line class="st0" x1="16" y1="13" x2="8" y2="13"/>
                <line class="st0" x1="16" y1="17" x2="8" y2="17"/>
                <polyline class="st0" points="10,9 9,9 8,9 "/>
             </svg>

             <h3>Formato de planificación</h3>
        </div>
        <div class="course__curriculum-meta">
<!--           <span class="time"> <i class="icon_clock_alt"></i> 14 minutes</span>-->
           <span class="default-btn" style="cursor: pointer;" @click="downloadPdfPlanificacion">
               <i class="fal fa-download"></i>
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                  >
                      Descargar
                  </v-tooltip>
           </span>
        </div>
    </div>
    <br v-if="planificacion">

    <h4>Brochure</h4>
    <div v-if="brochure && mostrarBrochure">
<!--        <div class="app-header">-->
<!--          <div>-->
<!--            <span v-if="showAllPages">-->
<!--              {{ pageCount }} página(s)-->
<!--            </span>-->

<!--            <span v-else>-->
<!--                  <button :disabled="page <= 1" @click="page&#45;&#45;" style="width: 20px; color: black;">❮</button>-->

<!--                  {{ page }} / {{ pageCount }}-->

<!--                  <button :disabled="page >= pageCount" @click="page++" style="width: 20px; color: black;">❯</button>-->
<!--            </span>-->

<!--            <label class="right">-->
<!--              <input v-model="showAllPages" type="checkbox" id="checkMostrarAllPagesBrochure">-->
<!--              Mostrar todas las páginas-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="app-content" style="height: 600px; overflow-y: auto;">-->
<!--            <vue-pdf-embed-->
<!--              :source="brochure"-->
<!--              ref="pdfRefBrochure"-->
<!--              :page="page"-->
<!--              @rendered="handleDocumentRender"-->
<!--            />-->
<!--        </div>-->
        <vue-pdf-app style="height: 90vh;" :pdf="brochure" theme="light" :key="curriculumKey" @pages-rendered="pagesRendered"></vue-pdf-app>
    </div>
    <p v-else class="">No existe información para mostrar</p>
    <br>
</div>
</template>

<script>
// import VuePdfEmbed from 'vue-pdf-embed'
import { AccordionList, AccordionItem } from "vue3-rich-accordion";
import "vue3-rich-accordion/accordion-library-styles.scss";

import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";

export default {
    name: "curriculumTab",
    components:{
        // VuePdfEmbed,
        VuePdfApp,
        AccordionList,
        AccordionItem,
    },
    props: {
        modulos: Array,
        brochure: String,
        planificacion: String,
        downloadPdfPlanificacion: { type: Function },
        curriculumKey: Number,
        mostrarBrochure: Boolean
    },
    data() {
        return {
            isLoading: true,
            // page: 1,
            // pageCount: 1,
            // showAllPages: false,
        }
    },
    // watch: {
    //   // showAllPages() {
    //   //   this.page = this.showAllPages ? null : 1
    //   // },
    //     curriculumKey(){
    //           document.getElementById("checkMostrarAllPagesBrochure").click();
    //     }
    // },
    methods: {
        handleDocumentRender() {
          this.pageCount = this.$refs.pdfRefBrochure.pageCount
        },
        pagesRendered(pdfApp) {
          pdfApp.pdfViewer.currentScaleValue = "0.9"
          pdfApp.pdfViewer.currentPageNumber = 2
            setTimeout(function() {
              pdfApp.pdfViewer.currentPageNumber = 1
            }, 500);
        //   console.log("pages rendered brochuretab")
            var button_brochure = document.getElementById("curriculum-tab-list")
            button_brochure.click()
          // if(this.permiteLoadPdf || this.permiteReloadPdf){
          //     if(this.permiteLoadPdf){
          //         this.permitirReloadPdf()
          //         this.noPermitirLoadPdf()
          //     }
          //     else if(this.permiteReloadPdf){
          //         this.noPermitirReloadPdf()
          //     }
          //     console.log("entra en if de brochure tab")
          //     // this.mostrarBrochureTab()
          //     var button_brochure = document.getElementById("curriculum-tab")
          //     button_brochure.click()
          }
    },
    mounted() {

    }
};
</script>

<style>
    /*.vue-pdf-embed > div {*/
    /*  margin-bottom: 8px;*/
    /*  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);*/
    /*}*/

    /*.app-header {*/
    /*  padding: 16px;*/
    /*  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);*/
    /*  background-color: #555;*/
    /*  color: #ddd;*/
    /*}*/

    /*.app-content {*/
    /*  padding: 24px 16px;*/
    /*  background-color: #ccc;*/
    /*}*/

    /*.right {*/
    /*  float: right;*/
    /*}*/

    /*.accordion-item__summary-icon::before {*/
    /*        content: '▼'!important;*/

    /*}*/
</style>
