<template>
  <div class="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
     <div class="course__faq mb-45">
          <div v-if="mostrarFaq">
<!--        <div class="app-header">-->
<!--          <div>-->
<!--            <span v-if="showAllPages">-->
<!--              {{ pageCount }} página(s)-->
<!--            </span>-->

<!--            <span v-else>-->
<!--                  <button :disabled="page <= 1" @click="page&#45;&#45;" style="width: 20px; color: black;">❮</button>-->

<!--                  {{ page }} / {{ pageCount }}-->

<!--                  <button :disabled="page >= pageCount" @click="page++" style="width: 20px; color: black;">❯</button>-->
<!--            </span>-->

<!--            <label class="right">-->
<!--              <input v-model="showAllPages" type="checkbox" id="checkMostrarAllPagesFaq">-->
<!--              Mostrar todas las páginas-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="app-content" style="height: 600px; overflow-y: auto;">-->
<!--            <vue-pdf-embed-->
<!--              :source="faq"-->
<!--              ref="pdfRefFaq"-->
<!--              :page="page"-->
<!--              @rendered="handleDocumentRender"-->
<!--            />-->
<!--        </div>-->


<!--              <vue-pdf-app style="height: 90vh;" :pdf="faq" theme="light" :key="faqKey" @pages-rendered="pagesRendered"></vue-pdf-app>-->

            <div class="faq-content">
              <div class="faq-articles">
                <article class="faq-accordion mt-20" v-for="item in faqLista" :key="item.token">

                  <input type="checkbox" class="tgg-title" :id="item.token">

                  <div class="faq-accordion-title">
                    <label :for="item.token">
                      <p><b>{{ item.faq_pregunta_respuesta.pregunta }}</b></p>
                      <span class="arrow-icon" style="color: #0F80C1;">
                        <i class="far fa-chevron-down"></i>
<!--                        <img src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg">-->
                      </span>
                    </label>
                  </div>

                  <div class="faq-accordion-content">
                    <p style="white-space: pre-line; text-align: justify;" v-html="item.faq_pregunta_respuesta.respuesta"></p>
                    <div class="text-center mb-10">
                        <a v-if="item.faq_pregunta_respuesta.tiene_boton" :href="item.faq_pregunta_respuesta.enlace_boton" target="_blank" class="e-btn e-btn-7 boton-respuesta">
                            {{ item.faq_pregunta_respuesta.contenido_boton }}
                        </a>
                    </div>
                    <div class="text-center">
                        <a v-if="item.faq_pregunta_respuesta.tiene_boton_2" :href="item.faq_pregunta_respuesta.enlace_boton_2" target="_blank" class="e-btn e-btn-7 boton-respuesta">
                            {{ item.faq_pregunta_respuesta.contenido_boton_2 }}
                        </a>
                    </div>
                  </div>

                </article> <!-- faq accordion -->
              </div>
            </div>
          </div>
          <p v-else class="">No existe información para mostrar</p>
          <br>
     </div>
 </div>
</template>

<script>

// import VuePdfEmbed from "vue-pdf-embed";
// import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";

export default {
   name:'FaqTab',
    components: {
        // VuePdfApp,
       // VuePdfEmbed
    },
    props: {
        faq: String,
        faqKey: Number,
        mostrarFaq: Boolean,
        faqLista: Array
    },
    data() {
        return {
            isLoading: true,
            // page: 1,
            // pageCount: 1,
            // showAllPages: false,
        }
    },
    // watch: {
    //   showAllPages() {
    //     this.page = this.showAllPages ? null : 1
    //   },
    //   faqKey(){
    //         document.getElementById("checkMostrarAllPagesFaq").click();
    //   }
    // },
    methods: {
        handleDocumentRender() {
          this.pageCount = this.$refs.pdfRefFaq.pageCount
        },
        pagesRendered(pdfApp) {
          pdfApp.pdfViewer.currentScaleValue = "0.7"
          pdfApp.pdfViewer.currentPageNumber = 2
            setTimeout(function() {
              pdfApp.pdfViewer.currentPageNumber = 1
            }, 500);
          // console.log("pages rendered faqtab")
            var button_brochure = document.getElementById("faq-tab-list")
            button_brochure.click()
          // if(this.permiteLoadPdf || this.permiteReloadPdf){
          //     if(this.permiteLoadPdf){
          //         this.permitirReloadPdf()
          //         this.noPermitirLoadPdf()
          //     }
          //     else if(this.permiteReloadPdf){
          //         this.noPermitirReloadPdf()
          //     }
          //     console.log("entra en if de brochure tab")
          //     // this.mostrarBrochureTab()
          //     var button_brochure = document.getElementById("curriculum-tab")
          //     button_brochure.click()
          }
    }
};
</script>

<style>
.faq-accordion {
  padding: 8px 0;
  border-bottom: 1px solid hsl(240, 5%, 91%);
}


/* FAQ card: main title */
/*================================================*/

/* checkbox tgg-title*/
input.tgg-title {
  appearance: unset;
  all:unset;
}

.faq-accordion-title label{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.faq-accordion-title p{
  font-size: 17px /*var(--font-size)*/;
  font-weight: bold;
  color: hsl(237, 12%, 33%);
}

.faq-accordion-title span{
  margin-left: auto;
  transition: transform .4s ease-in-out;
}


/* FAQ card: main content */
/*================================================*/

.faq-accordion-content {
  color: hsl(240, 6%, 50%);
  overflow: hidden;
  max-height: 0;
  transition: max-height .4s ease-in-out;
}


/* Effects */
/*================================================*/

/* main title, accordion title effects */

.faq-accordion-title:hover p{
  color: #0F80C1;
}

/* onclick "" */
.faq-accordion .tgg-title:checked + div>label>h2 {
  font-weight: 700;
}

.faq-accordion .tgg-title:checked + div>label>span {
  will-change: transform;
  transform: rotate(180deg);
}

/* main content, acordion text effect */

.faq-accordion .tgg-title:checked ~ .faq-accordion-content{
  will-change: max-height;
  max-height: 100%;
}

.boton-respuesta{
  border-radius: 25px;
}
</style>